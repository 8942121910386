import { useEffect, useState } from 'react';
import { InsuranceCooperationAuthenticationContext } from './InsuranceCooperationAuthenticationContext';
import { SuccessContainer } from './Components/SuccessContainer';
import { GetTokenContainer } from './Components/GetTokenContainer';
import { ErrorContainer } from './Components/ErrorContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getOAuthTokenExpiresDate, isInsuranceCooperationOAuthFailed } from 'redux/query';
import { FormBlockerContainer } from './Components/FormBlockerContainer';
import Style from './InsuranceCooperationAuthenticationContainer.style';
import { ROUTES } from 'utils';
import { sendInsuranceCooperationOAuthTokenRequest } from 'redux/actions';

export const InsuranceCooperationAuthenticationContainer = ({ children, provider }) => {
  const dispatch = useDispatch();
  const oAuthTokenExpiresDate = useSelector(getOAuthTokenExpiresDate);
  const isOAuthFailed = useSelector(isInsuranceCooperationOAuthFailed);

  const [showPopupError, setShowPopupError] = useState(false);
  const [allowFormSubmit, setAllowFormSubmit] = useState(!!(oAuthTokenExpiresDate && new Date() < oAuthTokenExpiresDate));
  const [authenticationError, setAuthenticationError] = useState(null);

  let interval = null;
  const setOAuthTokenExpiresInterval = () => {
    if (interval) {
      clearTimeout(interval);
    }
    if (new Date() < oAuthTokenExpiresDate) {
      interval = setTimeout(() => {
        setOAuthTokenExpiresInterval();
      }, 1000);
    } else {
      setAllowFormSubmit(false);
    }
  };

  const handleOnOpenAuthenticationWindow = () => {
    window.location.href = `${ROUTES.insuranceCooperationAuthentication}?provider=${provider}`;
  };

  const sendInsuranceOAuthToken = ({ code, tokenExpirationDate, provider }) => {
    dispatch(
      sendInsuranceCooperationOAuthTokenRequest({
        token: code,
        tokenExpirationDate,
        insuranceCooperationCompany: `${provider}`.toUpperCase()
      })
    );
    setAuthenticationError(null);
  };

  useEffect(() => {
    let queryString = window.location.search;

    const props = (() => {
      try {
        const urlParams = new URLSearchParams(queryString);
        const paramStr = urlParams.get('p') ?? null;
        if (!paramStr) {
          return null;
        }
        queryString = queryString.replace(`p=${paramStr}`, '');
        return JSON.parse(atob(paramStr));
      } catch (error) {
        return null;
      }
    })();

    if (!props) {
      return;
    }
    sendInsuranceOAuthToken(props);

    if (window.location.search !== queryString) {
      window.history.pushState({}, '', `${window.location.pathname}${queryString}${window.location.hash}`);
    }
  }, []);

  useEffect(() => {
    if (!oAuthTokenExpiresDate || new Date() > oAuthTokenExpiresDate) {
      if (allowFormSubmit) {
        setAllowFormSubmit(false);
      }
      return () => {
        if (interval) {
          clearTimeout(interval);
        }
      };
    }
    if (oAuthTokenExpiresDate && new Date() < oAuthTokenExpiresDate) {
      setAllowFormSubmit(true);
    }
    setOAuthTokenExpiresInterval();
    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
  }, [oAuthTokenExpiresDate]);

  useEffect(() => {
    if (isOAuthFailed) {
      setAuthenticationError('transferFailed');
    }
  }, [isOAuthFailed]);

  return (
    <Style id="anmeldung">
      <InsuranceCooperationAuthenticationContext.Provider
        value={{
          showPopupError,
          allowFormSubmit,
          authenticationError,
          setShowPopupError,
          provider,
          oAuthTokenExpiresDate,
          handleOnOpenAuthenticationWindow
        }}
      >
        {children({
          showPopupError,
          allowFormSubmit,
          authenticationError,
          setShowPopupError,
          provider,
          oAuthTokenExpiresDate,
          handleOnOpenAuthenticationWindow
        })}
      </InsuranceCooperationAuthenticationContext.Provider>
    </Style>
  );
};

InsuranceCooperationAuthenticationContainer.SuccessContainer = SuccessContainer;
InsuranceCooperationAuthenticationContainer.GetTokenContainer = GetTokenContainer;
InsuranceCooperationAuthenticationContainer.FormBlockerContainer = FormBlockerContainer;
InsuranceCooperationAuthenticationContainer.ErrorContainer = ErrorContainer;
