import { MainPage } from 'Container';
import styled from 'styled-components';
import { breakpoints } from 'Theme';
import { asRem } from 'utils';

export default styled(MainPage).attrs({ className: 'trust-portal' })`
  margin-top: 0;

  & > .container {
    padding: 0;
    max-width: 100%;
  }

  .header-section {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: ${asRem(72)};
    text-align: center;
    min-height: ${asRem(650)};

    @media (min-width: 425px) {
      min-height: ${asRem(570)};
    }

    @media (min-width: ${breakpoints.md}) {
      background-position: bottom ${asRem(-10)} left;
      background-size: 100% 100%;
      justify-content: center;
    }

    @media (min-width: ${breakpoints.lg}) {
      padding-top: ${asRem(36)};
    }

    @media (min-width: ${breakpoints.xl}) {
      min-height: ${asRem(600)};
    }

    .mindful-image {
      width: ${asRem(375)};
    }
  }

  .signet-section .row {
    margin: ${asRem(40)} auto 0;

    .dsgvo-box {
      padding: ${asRem(16)} ${asRem(42)};
    }

    .mime-elements-paragraph.dsgvo-box {
      margin-bottom: ${asRem(0)}!important;
      padding-bottom: ${asRem(8)}!important;
    }

    .download-icon {
      margin: 0 ${asRem(2)} ${asRem(4)} 0;
    }
  }

  .description-section {
    .description-image-wrapper {
      padding-top: ${asRem(160)};
      width: 100%;
      position: relative;

      @media (min-width: ${breakpoints.md}) {
        padding-top: 0;
      }

      .description-image {
        position: absolute;
        right: auto;
        left: 50%;
        margin-left: -${asRem(80)};
        top: 0;
        width: ${asRem(144)};

        @media (min-width: ${breakpoints.md}) {
          left: auto;
          margin-left: 0;
          right: 0;
          top: ${asRem(120)};
          width: ${asRem(144)};
        }

        @media (min-width: ${breakpoints.lg}) {
          top: 0;
          width: ${asRem(224)};
        }
      }

      .image-row-1 {
        padding-right: ${asRem(0)};

        @media (min-width: ${breakpoints.md}) {
          padding-right: ${asRem(260)};
        }

        @media (min-width: ${breakpoints.lg}) {
          padding-right: ${asRem(380)};
        }
      }
    }

    .main-list {
      h4 {
        text-align: left;
      }

      margin-bottom: 0;
      .list-icon {
        width: ${asRem(52)};
        margin-right: ${asRem(8)};
        align-self: center;
        margin-bottom: ${asRem(-4)};

        @media (min-width: ${breakpoints.md}) {
          margin-bottom: ${asRem(-8)};
        }
      }
    }

    .description-list {
      li {
        padding-right: ${asRem(30)};
      }
      .icon {
        align-self: flex-start;
        margin-right: ${asRem(20)};
      }
    }
  }

  .contact-section {
    .contact-list {
      li {
        padding-right: ${asRem(30)};
      }
    }

    .contact-card {
      padding: ${asRem(12)} ${asRem(36)};
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 0 ${asRem(36)};
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding: 0 ${asRem(72)};
      }

      h3 {
        font-size: ${asRem(24)};
        line-height: ${asRem(34)};

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          font-size: ${asRem(28)};
          line-height: ${asRem(38)};
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
          font-size: ${asRem(32)};
          line-height: ${asRem(42)};
        }
      }
    }
  }
`;
