import Style from './InsuranceCooperationAuthenticationPage.style';
import { platform } from 'config';
import { Headline, Paragraph, Button, Row, Col, Link, Spinner } from 'Common';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { ROUTES } from 'utils';

export const ALLOWED_PROVIDERS = {
  TK: platform.isProduction
    ? 'https://www.tk.de/balloon-anmeldung'
    : 'https://wt01-vorschau.tk-online.net/service/login/techniker/meine-tk/balloonapp-2173122'
};

export const InsuranceCooperationAuthenticationPage = () => {
  const { search } = useLocation();
  const queryArguments = useMemo(() => new URLSearchParams(search), [search]);

  const provider = queryArguments.get('provider') || null;
  const status = queryArguments.get('status') || null;

  const [redirectToPage, setRedirectToPage] = useState(platform.isProduction);

  const getCallbackUrl = ({ props = {} }) => {
    const args = {
      status: '1',
      ...props,
      provider
    };

    const queryString = Object.keys(args)
      .map((key) => `${key}=${args[key]}`)
      .join('&');

    return `${window.location.origin}${ROUTES.insuranceCooperationAuthentication}?${queryString}`;
  };

  const handleOnReferer = (props) => () => {
    window.location.href = getCallbackUrl({ props });
  };

  const getArguments = () => {
    const args = {};
    queryArguments.forEach((value, key) => (args[key] = value));
    return args;
  };

  const createTokenExpirationDate = () => {
    const expiresAt = new Date(new Date().getTime() + 12 * 60 * 1000);
    expiresAt.setSeconds(0);
    return expiresAt;
  };

  useEffect(() => {
    if (status === null) {
      return;
    }

    const args = getArguments();
    const props = { status, provider, tokenExpirationDate: `${status}` === '1' ? createTokenExpirationDate() : null, ...args };

    const route = ROUTES[provider] ?? ROUTES[provider.toLowerCase()];
    if (route) {
      window.location.href = `${route}?p=${btoa(JSON.stringify(props))}#anmeldung`;
    }
  }, [status]);

  useEffect(() => {
    if (!redirectToPage || !!status) {
      return;
    }
    window.location.href = `${ALLOWED_PROVIDERS[provider]}`;
  }, [redirectToPage]);

  const renderLoader = () => (
    <Style>
      <Spinner />
    </Style>
  );

  if (status) {
    return renderLoader();
  }

  if (!provider || !Object.keys(ALLOWED_PROVIDERS).includes(provider)) {
    window.close();
    return renderLoader();
  }

  return (
    <Style>
      {!platform.isProduction && !redirectToPage && (
        <div className="text-center dev-frame">
          <br />
          <Headline level={1}>DEV</Headline>
          <Headline level={2}>Authentication Provider</Headline>

          <Row className="data-provider">
            <Col xs={4}>
              <Paragraph className="bold">Provider</Paragraph>
            </Col>
            <Col xs={8}>
              <Paragraph>{provider}</Paragraph>
            </Col>
          </Row>
          <Row className="data-provider">
            <Col xs={6}>
              <Button className="decline-button" onClick={handleOnReferer({ status: '0' })}>
                Ablehnen
              </Button>
            </Col>
            <Col xs={6}>
              <Button onClick={handleOnReferer({ code: 'mmtest-RANDOM-AUTH-KEY' })}>Bestätigen</Button>
            </Col>
          </Row>
          <br />
          <Link onClick={() => setRedirectToPage(true)}>IFrame öffnen (richtige Authentifizierung)</Link>
        </div>
      )}
    </Style>
  );
};
