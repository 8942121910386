import { store, rehydrateStore } from './configureStore';
import { applicationBootstrapped, logoutUser } from 'redux/actions';
import { getToken } from 'redux/query';
import { checkUserTokenApiRequest } from 'api/balloon';
import { requestApi } from 'utils';
import Cookies from 'js-cookie';

async function hydrateStore() {
  await rehydrateStore(store);
  const state = store.getState();

  const token = getToken(state);

  if (token) {
    const now = new Date().getTime();
    const sessionCookie = parseInt(Cookies.get('user_session'), 10);
    if (!sessionCookie || sessionCookie + 86400000 < now) {
      // expires after 24 hours
      Cookies.set('user_session', now, { path: '/' });
      store.dispatch(logoutUser());
    } else {
      try {
        await checkUserTokenApiRequest({ token });
      } catch (error) {
        // logout the user to create a new guest user token before bootstrapping the app
        if (error.response.status === 401) {
          store.dispatch(logoutUser());
        }
      }
    }
  }

  token && requestApi.setAuthToken(token);
  store.dispatch(applicationBootstrapped());
  return true;
}

export default () =>
  new Promise(async (resolve) => {
    await hydrateStore();
    resolve(true);
  });

console.warning = () => {};
